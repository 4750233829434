import React, { useState } from 'react'
import { useEffect } from 'react';
//import ReactGA from "react-ga4";
import { Messages } from '../Components/Messages'
import { Toolbar } from '../Components/Toolbar'
import { Footer } from '../Components/Footer'
import { Header } from '../Components/Header'
import { RecommendationsData } from '../Data/RecommendationsData'
import { Authenticator } from '@aws-amplify/ui-react'
import { getUserByUserId , getChatSessionByUserId , creatChatSession, getBotUserByUserIdAsynWrapper , updateUserAgreed } from '../Data/AllDBQueries'
import {  sendChatMessage } from '../Data/AllDBQueries'
import {logPageView,logUserAction} from '../Data/GoogleAnalyticsLib.js';
import {UserContext} from '../Context/UserContext';
import { ChatSessionContext } from '../Context/ChatSessionContext';

import { withAuthenticator  } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { BottomSheet } from '../Components/BottomSheet';
import { AppContext } from '../Context/AppContext';



//ReactGA.initialize("G-LPHZDMWZRM");


 const Home = ({ signOut, user }) => {
  //console.log(user);
  const bottomSheetInitContent = {
    title:'init title',
    bodyText: ' init body text'
  }
  
  const [text,setText] = useState('');
  const [disabled,setDisabled] = useState(false);
  const [loader,setLoader] = useState(false);
  const [recommendationPanelVisible,setRecommendationPanelVisible]  = useState(true);
  const [invokeAPI,setInvokeAPI] = useState(false);
  const [recommendedQuestion,setRecommendedQuestion] = useState(RecommendationsData[0]);
  const [chatSessionForLoggedInUser,setChatSessionForLoggedInUser] = useState(null);
  const [bottomSheetOpen,setBottomSheetOpen] = useState(false);
  const [bottomSheetContent,setBottomSheetContent] = useState(bottomSheetInitContent);
  
  const initMessage = {
    nextIndex:0,
    messageList: 
    [

      {
        id:0,
        chatSessionID: "",
        message: "",
        receiverID: "",
        senderID:""

      }
    ]
};
const chatSessionCtx = {
  chatSessionId: 'abcd',
  chatSessionBotUser : {
    id:'xyz',
    username: 'nisthana'
  }
};
  const [messages, setMessages] = useState(initMessage);
  const [chatSessionId,setChatSessionId] = useState('');
  const [chatSessionContext,setChatSessionContext] = useState(null);
  const [initializingApp,setInitializingApp] = useState(false);
  const [userContext,setUserContext] = useState(null);
  const [userAgreedToTerms,setUserAgreedToTerms] = useState(false);
  
  const appContext = {
    cognitoUser: user,
    setDisabled: setDisabled,
    setText: setText,
    handleMessageSend: handleMessageSend,
    setUserContext: setUserContext
  }
  

  useEffect(() => { 
    
    //setChatSessionContext(chatSessionCtx);
    //console.log("value of disabled = " + disabled);
    logPageView("/","Home");
    

    
    //ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    //console.log("Home in useEffect...");
    const signedInUserId = user.attributes.sub;
    //console.log("initializingApp " + initializingApp);
    if (!initializingApp) {
      //console.log("app not initialized ");
      setInitializingApp(true);
      //console.log("===calling getBotUserByUserIdAsynWrapper to get the Bot User Object");
      
      getBotUserByUserIdAsynWrapper(user).then((data)=>{
        //console.log("<<< after promised fullfilled for getBotUserByUserIdAsynWrapper  - botUser = %o",data);
        setUserContext(data);
        const botUser = data;
        //console.log("=== calling getChatSessionByUserId");
        getChatSessionByUserId(signedInUserId,botUser, setChatSessionId,messages,setMessages).then((data)=>{
          //console.log("In Home - chatSession = %o",data);
          setInitializingApp(false);
          //setUserContext(data);
        });
      });
      
    }

   
    //console.log("//after callng getChatSessionByUserId chatSessionId ="+chatSessionId);
    
    
    //console.log(messages);
    //console.log("//end of useSession")


  },[chatSessionId]);

  function handleMessageSend() {
    //console.log("handleMessageSend userContext %o",userContext);
    const userAgreeddToTerms = userContext && userContext.userAgreeddToTerms;
    
    if (!userAgreeddToTerms) {
      if (text === 'Yes I agree') {
        //console.log("updading user object with agreement");
        setUserAgreedToTerms(true);
        
        const userContextCopy = {...userContext};
        userContextCopy.agreedJustNow = true;
        userContextCopy.userAgreeddToTerms = true;
        setUserContext(userContextCopy);
        updateUserAgreed(userContext.id);
      } else {
        alert("It's great you are eager to start but you need to agree to my terms & conditions first. Just send the message 'Yes I Agree' (without quotes) or tap the 'Yes I agree' button 😃");
        return;
      }
    }
   
    logUserAction("Clicks_Category","Message_Sent_Action","Message_Sent")
    /*ReactGA.event({
      category: "Clicks_Category",
      action: "",
      label: "", // optional
      value: 1, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    */
    //console.log("handleMessageSend text="+text);

    setDisabled(true);
    setLoader(true);
    setRecommendationPanelVisible(false);
    //console.log("handling sending message from user");
   // console.log("chatSessionId="+chatSessionId);
    //console.log("text="+text);
    const senderID = user.attributes.sub
    const receiverID = 'e84fc505-7627-4e48-b460-dbee87482d2b';
    //console.log("senderID="+senderID);
    //console.log("receiverID="+receiverID);
    sendChatMessage(chatSessionId,messages,setMessages,text,senderID,receiverID,setInvokeAPI);
    //console.log("//handling sending message from user");
   }
   if (initializingApp) {
    return (
      <div className="mainContainer">
          
          <h2>Loading the app, please wait...</h2>
        </div>
    )
   }
  return (
    
      
        
    <div className="mainContainer">
        <AppContext.Provider value={appContext}>
        <UserContext.Provider value={userContext}>
          <ChatSessionContext.Provider value={chatSessionContext}>
        <Header user={user} bottomSheetOpen={bottomSheetOpen} setBottomSheetOpen={setBottomSheetOpen} bottomSheetContent={bottomSheetContent} setBottomSheetContent={setBottomSheetContent}/>
        
        <Messages 
          user={user}
          chatSessionId={chatSessionId}
          text={text} 
          setText={setText} 
          loader={loader} 
          setLoader={setLoader} 
          invokeAPI={invokeAPI} 
          setInvokeAPI={setInvokeAPI} 
          messages={messages} 
          setMessages={setMessages}
          recommendationPanelVisible={recommendationPanelVisible}
          setRecommendationPanelVisible={setRecommendationPanelVisible}
          recommendedQuestion={recommendedQuestion}
          setRecommendedQuestion={setRecommendedQuestion}
          setDisabled={setDisabled}/>
        <Footer text={text} setText={setText} handleMessageSend={handleMessageSend} disabled={disabled} loader={loader}/>
        
        <BottomSheet user={user} bottomSheetOpen={bottomSheetOpen} setBottomSheetOpen={setBottomSheetOpen} bottomSheetContent={bottomSheetContent}/>    
        </ChatSessionContext.Provider>
        </UserContext.Provider>    
        </AppContext.Provider>
    </div>
    
  
    
  );
}
export default withAuthenticator(Home);
