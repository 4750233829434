import React from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { getUser , chatSessionsByUserId , getChatSession, usersByCognitoUserId } from '../graphql/queries';
import { createChatSession , createChatMessage, createUser , updateUser } from '../graphql/mutations';
import { MessageTypes } from '../Data/MessageTypes.js'
import { RecommendationsData } from '../Data/RecommendationsData'

export async function createBotUserAsyncWrapper(user){
    const cognitoUserId = user.attributes.sub;
    const cognitoUserName = user.username;
    const cognitoUserEmail = user.attributes.email;
    const cognitoAuthMethod = 1;
    await createBotUser(cognitoUserId,cognitoUserName,cognitoUserEmail,cognitoAuthMethod);
}
export async function updateUserAgreed(botUserId){
        
        const updateUserInput = {
            input: {
    
                id: botUserId,
                userAgreeddToTerms: true,
      
            }
           
        };
        const response = await API.graphql(graphqlOperation(updateUser,updateUserInput ));
        //console.log('after update user %o',response);
}

export async function getBotUserByUserIdAsynWrapper(user){
    //console.log(">>>>Inside getBotUserByUserIdAsynWrapper");
    const cognitoUserId = user.attributes.sub;
    const cognitoUserName = user.username;
    const cognitoUserEmail = user.attributes.email;
    const cognitoAuthMethod = 1;
    //console.log("===calling getBotUserByUserId");
    const botUser = await getBotUserByUserId(cognitoUserId);
    //console.log("<<< getBotUserByUserIdAsynWrapper botUser %o",botUser);
    if (botUser) {
        
        //console.log("<<< found bot user botUsers=%o",botUser);
        return botUser;
        
    } else {

        //console.log("<<<< no user found so creating a new user");
        try {
            //console.log("===calling createBotUser");
            const botUser = await createBotUser(cognitoUserId,cognitoUserName,cognitoUserEmail,cognitoAuthMethod);
            //console.log('<<< created a new bot user %o ',botUser);
            return botUser;
        } catch (err) {
           // console.log("\tStep 5: Error while creating a Bot User %o",err);
            return null;
        }
       
    }
    
}

export async function getBotUserByUserId(cognitoUserId) {
    //console.log(">>>>Inside getBotUserByUserId");
    //console.trace();
    
    try {
        //console.log("==== caling graphqlOperation(usersByCognitoUserId");
        const response = await API.graphql(graphqlOperation(usersByCognitoUserId, {cognitoUserId: cognitoUserId}));
        //console.log('<<<< data returned by usersByCognitoUserId %o',response.data.usersByCognitoUserId.items);
        
        const botUsers = response.data.usersByCognitoUserId.items;
        
        if (botUsers !== null && botUsers.length > 0) {
            const botUser = botUsers[0];
            //console.log("returning bot user %o back to the caller",botUser);
            return botUser;
        } else {
            //console.log("returning null bot user back to the caller");
            return null;
        }
        
       

    } catch (err) {
        console.log("error in getBotUserByUserId %o",err)
        //return null;
    }
    
}

export async function createBotUser(cognitoUserId,cognitoUserName,cognitoUserEmail,cognitoAuthMethod) {
    //console.log("Inside createBotUser");
    const createUserInput = {
        input: {

            cognitoUserId: cognitoUserId,
            userAgreeddToTerms: false,
            cognitoUserName: cognitoUserName,
            cognitoUserEmail: cognitoUserEmail,
            cognitoAuthMethod: cognitoAuthMethod
  
        }
       
    };
    //console.log("createBotUser::createUserInput %o",createUserInput);
    try {
        //console.log("====calling graphql(graphqlOperation(createUser");
        const response = await API.graphql(graphqlOperation(createUser, createUserInput));
       // console.log('<<<<<createUser response %o',response);
        return response.data.createUser;
    } catch (err) {
        console.log("Error while creating user %o",err);
        
    }
}
export async function getUserByUserId(userId)  {
        
    //console.log("getUserByUserId");
    //console.log("userId="+userId);
    
    try {
        const userData = await API.graphql(graphqlOperation(getUser, {id: userId}));
        //console.log(userData);
        return userData;
    } catch (err) {
        console.log("Error while getting user by userId user %o",err);
        
    }
    
}



export async function getChatSessionByUserId(userId,botUser,setChatSessionId,messages,setMessages)  {
   // console.log("Inside getChatSessionByUserId");
    //console.log("Step 2: getChatSessionByUserId");
    //console.log("Step 2: userId="+userId);
    //console.trace();
    const params = {
        userId: userId
    };
    try {
        //const res = await API.graphql(graphqlOperation(chatSessionsByUserId, params));
        
        //console.log("====calling graphqlOperation(chatSessionsByUserId ");
        await API.graphql(graphqlOperation(chatSessionsByUserId, params)).then((result)=>{
           // console.log("<<<< after promise from chatSessionsByUserId..%o",result);
            
            const chatSessions = result.data.chatSessionsByUserId.items;
            const chatSession = chatSessions[0];
            if (chatSession) {
                //console.log("<<<< found a chat session")
                //console.log("<<< setting the chatSessionId to " + chatSession.id);
                setChatSessionId(chatSession.id);
               // console.log("====calling graphqlOperation(getChatSession, ");
                API.graphql(graphqlOperation(getChatSession, { id: chatSession.id })).then((result)=>{
                //console.log('after promise of getChatSession ');
                //console.log(result);
                //console.log('//after promise of getChatSession ');
                //const messageList2 = result.data.getChatSession.chatMessages.items.messageList;
                const messageList2 = result.data.getChatSession.chatMessages.items;
                //const chatGPTMessageListCopy = [...messages];
                //chatGPTMessageListCopy.push(chatGPTUserObj);
                //const messages = result.data.getChatSession.chatMessages.items;
                //console.log("printing messages....")
                //console.log(messageList2);
                const messagesObjCopy = {...messages};
                
                messagesObjCopy.messageList = messageList2;

                setMessages(messagesObjCopy);
                
            
            });
        } else {
        
            //console.log("Could not find a chat session for the user " + userId);
            //console.log("==== calling createChatSessionForUser");
            createChatSessionForUser(userId,botUser,setChatSessionId);
            //console.log("<<< after createChatSessionForUser");
        }
        }).catch((err)=>{
            console.log("error in chatSessionsByUserId...%o",err);
            
        })
        
 

        
       /* const chatSessions = res.data.chatSessionsByUserId.items;
        
        console.log("Step 3: chatSessions after the call..printing chatSessions....");
        console.log(chatSessions);
        const chatSession = chatSessions[0];
        console.log("Step 3: chatSession after the call printing chatSession...");
        console.log(chatSession);
        const chatSessionId = chatSession.id;
        console.log("Step 3: after the call printing chatSessionId...");
        console.log(chatSessionId);
       
        console.log("Step 3: End...");
        */
        
        
    } catch (e) {
        console.log(e);
    }
    
}

export async function createChatSessionForUser(userId,botUser,setChatSessionId)  {
    /* We first need to create a new User row 
    1. Check if the user already exists. If so, create the session passing the bot user id 
    2. If not, create the user first and then reate the session passing the bot user id 
    */
   //console.log("Inside createChatSessionForUser");
   //console.trace();
   
    //console.trace();
    //console.log("creatChatSession");
    //console.log(userId);
    const receiverID = 'e84fc505-7627-4e48-b460-dbee87482d2b';
    const createChatSessionParams = {
        input: { 
            userId: userId,
            receiverID: receiverID,
            botUserId: botUser.id
         }
    };
    
    try {
        //console.log("=== calling graphqlOperation(createChatSession");
        API.graphql(graphqlOperation(createChatSession, createChatSessionParams)).then((result)=>{
           //console.log('after promise');
           //console.log(result);
           //console.log("<< after promise of graphqlOperation(createChatSession %o",result);
           const chatSession = result.data.createChatSession;
           //console.log(chatSession);
           const chatSessionId = chatSession.id;
           setChatSessionId(chatSessionId);
           //console.log("chatSessionId="+chatSession.id);
           const createChatMessageParams = {
               input: { 
                   chatSessionID: chatSessionId,
                   senderID: 'e84fc505-7627-4e48-b460-dbee87482d2b',
                   receiverID: userId,
                   messageType: MessageTypes.ADMIN_MESSAGE_TC,
                   message: 'Yo yet to be extinct human - I am Dodo 😐, the dumbest chat bot out there. I will answer all your questions in the most dumbest way possible. Let\'s start the fun, but first you need to agree to my terms and conditions 👻\n\n1. I am a toy chat bot and should not be taken seriously 🤪\n\n2. I will store all your messages for my creators to train me on. So please DO NOT send any sensitive data such as real names, email, phone, social security, credit card details, address etc. 😬\n\n3. Keep this bot clean of inappropriate messages so no profanity or bad language please. 🤬\n\n4. I will BLOCK you if I see you violating any rules 🫠\n\n5. I can change these rules anytime without notifying you 😎\n\n6. I am still in beta so there might be lots of bugs 🪲🐜🐞\n\nDo you agree? 👍🏽 👍🏾 👍🏿 👍🏼'  
               }
           };
           //console.log('trying to create message');
           //console.log(createChatMessageParams);
            API.graphql(graphqlOperation(createChatMessage, createChatMessageParams))
            .then((result)=>{
              // console.log("after message create promise..");
               //console.log(result);

               
           }).catch((err)=>{
               console.log("error");
               console.log(err);
           });
               
           
       });
       
  

   } catch (e) {
       console.log(e);
   }
    
 
    
}

export async function sendChatMessage(chatSessionId,messages,setMessages,text,senderID,receiverID,setInvokeAPI)  {
   //console.log("sendChatMessage...");
    //console.log(chatSessionId);
  
    const createChatMessageParams = {
        input: { 
            chatSessionID: chatSessionId,
            senderID: senderID,
            receiverID: receiverID,
            messageType: MessageTypes.USER_MESSAGE,
            message: text  
        }
    };
   //console.log("sendChatMessage  input params");
   //console.log(createChatMessageParams);
  
    try {
        //console.log("trying createChatMessage GraphQL...")
         API.graphql(graphqlOperation(createChatMessage, createChatMessageParams)).then((result)=>{
           //console.log("after promise")
            //console.log(result);
            const messageList = messages.messageList;
            const messageList2 = [...messageList];
            const messageFromDb = result.data.createChatMessage;
            
            //console.log("messageFromDb...");
            //console.log(messageFromDb);
            messageList2.push(messageFromDb);
             
             const messagesObjCopy = {...messages};
             
             messagesObjCopy.messageList = messageList2;
             setMessages(messagesObjCopy);
             
            
            setInvokeAPI(true);
             
             
        }).catch((err)=>{
            console.log("error in trying createChatMessage GraphQL... promise catch")
            console.log(err);
        });
        
    } catch (err) {
        console.log("error in trying createChatMessage GraphQL...main catch")
        console.log(err);
    }
    
 
    
}
export async function saveChatGPTResponseToDB(chatSessionId,messages,setMessages,text,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion)  {
    //console.log("saveChatGPTResponseToDB...");
    //console.log(chatSessionId);
   
    const createChatMessageParams = {
        input: { 
            chatSessionID: chatSessionId,
            senderID: senderID,
            receiverID: receiverID,
            messageType: MessageTypes.BOT_RESPONSE,
            message: text  
        }
    };
   //console.log("saveChatGPTResponseToDB...chat message input params");
  //console.log(createChatMessageParams);
  
    try {
        //console.log("saveChatGPTResponseToDB trying createChatMessage GraphQL...")
        API.graphql(graphqlOperation(createChatMessage, createChatMessageParams)).then((result)=>{
            //console.log("//after createChatMessage GraphQL...")
            //console.log(result);
            const chatMessage = result.data.createChatMessage;
            const messageList2 = [...messages.messageList];
      /** uncomment  */
      
      messageList2.push(chatMessage )
      
      
     
      const messagesObjCopy = {...messages};
      
      messagesObjCopy.messageList = messageList2;
      setMessages(messagesObjCopy);
      setInvokeAPI(false);
      setText('');
      setDisabled(false);
      setLoader(false);
      setRecommendationPanelVisible(true);
        const recommendationsIndex =  Math.floor(Math.random() * RecommendationsData.length);
        const recommendedQuestion1 = RecommendationsData[recommendationsIndex];
        setRecommendedQuestion(recommendedQuestion1);
        }).catch((err)=>{
            console.log("error saveChatGPTResponseToDB... promise catch")
            console.log(err);
        });
        
    } catch (e) {
        console.log(e);
    }
    
 
    
}

