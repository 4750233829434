import React from 'react'
import { HeaderGuest } from '../Components/HeaderGuest'
import { GuestContent } from '../Components/GuestContent'

export const IntroPage = () => {
  return (
    <div className="mainContainer">
    <HeaderGuest />
    <GuestContent/>
    </div>
  )
}
