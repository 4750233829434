import React, { useState } from 'react'
import { Input }from '../Components/Input'
import { Message }from '../Components/Message';
import { useEffect, useRef } from 'react';
import { UserContext } from '../Context/UserContext';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify'
import {  sendChatMessage , saveChatGPTResponseToDB } from '../Data/AllDBQueries'
import { AppContext } from '../Context/AppContext';
import { SearchMessage } from './SearchMessage';
import { useSearchParams } from "react-router-dom";
//import { getEmbeddings } from '../Data/SearchUtils'


export const SearchMessages = ({user,chatSessionId,text,setText,loader,setLoader,invokeAPI,setInvokeAPI,messages,setMessages,recommendationPanelVisible,setRecommendationPanelVisible,recommendedQuestion,setRecommendedQuestion,setDisabled,pineconeClient}) => {
  
  const inputElement = useRef(null);
  const thisUserId = '10001';
  let [searchParams, setSearchParams] = useSearchParams();
  //console.log("Messages component - chatSessionId= "+chatSessionId);
  
  const [chatGPTMessageList,setcChatGPTMessageList] = useState([]);
  const userContext = React.useContext(UserContext); 
  const appContext = React.useContext(AppContext); 
  //console.log("value fom context %o",value);
  function getCustomersAlsoAsked(searchResults) {
    let customersAlsoAsked = '';
    for (let index = 0; index < searchResults.length; index++) {
      const element = searchResults[index];
      
      const docMetadata = element.metadata;
     
      const question = docMetadata.question;
      customersAlsoAsked = customersAlsoAsked + question + "\n"
      //const docId = docMetadata.wiki-id;
      
      
    }
    return customersAlsoAsked;
  }
  function getPrompt(originalSearchQuery, searchResults) {
    
    let promptContext = ''
    for (let index = 0; index < searchResults.length; index++) {
      const element = searchResults[index];
      
      const docMetadata = element.metadata;
     
      const docText = docMetadata.answer;
      
      //const docId = docMetadata.wiki-id;
      
      promptContext = promptContext + docText + '\n\n------\n\n';
    }
    //console.log('promptContext...');
    //console.log(promptContext);
    const prompt_start = "Answer the question based on the context below.\n\nContext:\n";
    const prompt_end = "\n\nQuestion: " + originalSearchQuery + "\nAnswer:";
    const prompt = prompt_start +  promptContext + prompt_end;
    //console.log("prompt for LLM....");
    //console.log(prompt);
  
    return prompt;
  }
  useEffect(() => {
    
    /*inputElement.current.onfocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };
    */
    //console.log("Message Component useEffect called");
    //console.log("chatGPTMessageList...");
    //console.log(chatGPTMessageList);
    
    
    const stupidText = text;

    
    
    
    if (invokeAPI) {
      //console.log("here...");
      
      setLoader(true);
      
      const getAdminResponseForUserAgreement = async () => {
        const content = "Thank you for agreeing to my terms and conditions. You are sweet! Now you can enjoy the app"
        const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
        const receiverID =   user.attributes.sub;
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
        saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
      }
      
      const getChatGPTResponse = async () => {
        const { Configuration, OpenAIApi } = require("openai");
        const configuration = new Configuration({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          
        });
        //console.log("key...");
        //console.log(process.env.REACT_APP_OPENAI_API_KEY);
        const openai = new OpenAIApi(configuration);
        //console.log("user text = " + text);
        //console.log('chatGPTMessageListCopy %o', chatGPTMessageListCopy);
        //console.log("going to create embeddings for " + text);
        
        const embeddings = await openai.createEmbedding({
          model: "text-embedding-3-small",
             input: text,
             encoding_format: "float",
         });
         
        //console.log("embeddings...");
        //console.log(embeddings);
        const embeddingsVector = embeddings.data.data[0].embedding;
        //console.log('vector len='+embeddingsVector.length);
        //console.log("embeddingsVector...")
        //console.log(embeddingsVector);
        //console.log("pinecone index desc...")
        //const pineconeIndex = await pineconeClient.describeIndex('wiki');
        //console.log('logging pinecone index...');
        //console.log(pineconeIndex);
        let pineconeIndexName = "";
        switch(searchParams.get('bankname')){
          case "wellsfargo" : pineconeIndexName = "banking-wellsfargo-faqs"; break;
          case "chime" : pineconeIndexName = "banking-chime-faqs"; break;
          default: pineconeIndexName = "banking-wellsfargo-faqs";
        }
        const pineconeIndex = pineconeClient.index(pineconeIndexName);
        const queryResponse1 = await pineconeIndex.namespace("").query({
          topK: 3,
          vector: embeddingsVector,
          includeValues: false,
          includeMetadata: true
        });
        console.log('queryResponse1 from pinecone');
        console.log(queryResponse1);
        const pageUrl = queryResponse1.matches[0].metadata.page_url;
        console.log("pageUrl="+pageUrl);
        const matchedDocList = queryResponse1.matches;
        const promptForLLM = getPrompt(text,matchedDocList);
        console.log("LLM prompt " + promptForLLM)
        const chatGPTUserObj = {
          role:'user',
          content:promptForLLM
        };
        const chatGPTMessageListCopy = [...chatGPTMessageList];
        chatGPTMessageListCopy.push(chatGPTUserObj);
        const completion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: chatGPTMessageListCopy ,
        });
        //console.log("response from openAI...");
        //console.log(completion.data.choices[0].message);
    
        chatGPTMessageListCopy.push(completion.data.choices[0].message);
        

        setcChatGPTMessageList(chatGPTMessageListCopy);
        const role = completion.data.choices[0].message.role;
        const contentFromChatGPT = completion.data.choices[0].message.content;
        //console.log("contentFromChatGPT = %o",contentFromChatGPT);
        let customersAlsoAsked = getCustomersAlsoAsked(matchedDocList);
        
        let contentSavedInDB = contentFromChatGPT + "\n\nCustomers also asked:\n" + customersAlsoAsked + "\nLink: " + pageUrl;
        
          const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
          const receiverID =   user.attributes.sub;
          //console.log("receiverID = " + receiverID);
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
          saveChatGPTResponseToDB(chatSessionId,messages,setMessages,contentSavedInDB,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
        //console.log(contentSavedInDB);
        /** move this to all db queries */
        
        

        
        
      
      };
      //console.log("making a request...");
      //console.log("text while making request = " + text)
      //console.log("userContext = %o",userContext);
      const agreedJustNow = userContext.agreedJustNow;
      //console.log('agreedJustNow %s',agreedJustNow)
      if (agreedJustNow && text === 'Yes I agree')  {
        //console.log("getting response from admin....");
        const userContextCopy = {...userContext};
        userContextCopy.agreedJustNow = false;
        appContext.setUserContext(userContextCopy);
        getAdminResponseForUserAgreement();

      } else {
        //console.log("getting response from chat GPT....");
        getChatGPTResponse();
      }
      
      
      //sendChatMessage(chatSessionId,text,senderId,receiverId);

      
      
    }
    
  },[invokeAPI]);
  
  
  
  const messageList = messages.messageList;

  //console.log("messageList in TranslateMessages ==== %o",messageList);
  //console.log("MessageComponent...");
  
  const nextIndex = messages.nextIndex + 1;
  
  

  return (
        <div className='scrollable-area'>
          
          <div className="messages">
            
            {
              messageList.map(thisMessage=>(
                <SearchMessage key={thisMessage.id} user={user} message={thisMessage}  />
                
              )
                )
            }
            
            
            
      </div>
     
  </div>
  )
}
