import React, { useState } from 'react'
import { Input }from '../Components/Input'
import { Message }from '../Components/Message';
import { useEffect, useRef } from 'react';
import { UserContext } from '../Context/UserContext';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify'
import {  sendChatMessage , saveChatGPTResponseToDB } from '../Data/AllDBQueries'
import { AppContext } from '../Context/AppContext';

export const Messages = ({user,chatSessionId,text,setText,loader,setLoader,invokeAPI,setInvokeAPI,messages,setMessages,recommendationPanelVisible,setRecommendationPanelVisible,recommendedQuestion,setRecommendedQuestion,setDisabled}) => {
  
  const inputElement = useRef(null);
  const thisUserId = '10001';
  //console.log("Messages component - chatSessionId= "+chatSessionId);
  
  const [chatGPTMessageList,setcChatGPTMessageList] = useState([]);
  const userContext = React.useContext(UserContext); 
  const appContext = React.useContext(AppContext); 
  //console.log("value fom context %o",value);
  useEffect(() => {
    
    /*inputElement.current.onfocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };
    */
    //console.log("Message Component useEffect called");
    //console.log("chatGPTMessageList...");
    //console.log(chatGPTMessageList);
    
    
    const stupidText = 'You are a stupid assistant that gives most stupid answers to my question: ' + text;
    if (invokeAPI) {
      
      setLoader(true);
      const chatGPTUserObj = {
        role:'user',
        content:stupidText
      };
      const chatGPTMessageListCopy = [...chatGPTMessageList];
      chatGPTMessageListCopy.push(chatGPTUserObj);
      const getAdminResponseForUserAgreement = async () => {
        const content = "Thank you for agreeing to my terms and conditions. You are sweet! Now you can enjoy the app"
        const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
        const receiverID =   user.attributes.sub;
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
        saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
      }
      const getChatGPTResponse = async () => {
        const { Configuration, OpenAIApi } = require("openai");
        const configuration = new Configuration({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });
        //console.log("key...");
        //console.log(process.env.REACT_APP_OPENAI_API_KEY);
        const openai = new OpenAIApi(configuration);
        //console.log("user text = " + text);
        
        const completion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: chatGPTMessageListCopy ,
        });
        //console.log("response from openAI...");
        //console.log(completion.data.choices[0].message);
        chatGPTMessageListCopy.push(completion.data.choices[0].message);
        

        setcChatGPTMessageList(chatGPTMessageListCopy);
        const role = completion.data.choices[0].message.role;
        const content = completion.data.choices[0].message.content;
        const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
        const receiverID =   user.attributes.sub;
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
        saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
        //console.log(content);
        /** move this to all db queries */
        /*
        const messageList2 = [...messageList];
      const nextIndex = messages.nextIndex + 1;
      messageList2.push({
        messageId:nextIndex,
        senderId:100,
        senderName:'Dodo',
        receiverId:101,
        receiverName:'Me',
        message:content
      })
      
      const messagesObjCopy = {...messages};
      messagesObjCopy.nextIndex=nextIndex;
      messagesObjCopy.messageList = messageList2;
      setMessages(messagesObjCopy);
      setInvokeAPI(false);
      setText('');
      setDisabled(false);
      setLoader(false);
      setRecommendationPanelVisible(true);
        const recommendationsIndex =  Math.floor(Math.random() * RecommendationsData.length);
        const recommendedQuestion1 = RecommendationsData[recommendationsIndex];
        setRecommendedQuestion(recommendedQuestion1);
      */
      
      
      };
      //console.log("making a request...");
      //console.log("text while making request = " + text)
      //console.log("userContext = %o",userContext);
      const agreedJustNow = userContext.agreedJustNow;
      //console.log('agreedJustNow %s',agreedJustNow)
      if (agreedJustNow && text === 'Yes I agree')  {
        //console.log("getting response from admin....");
        const userContextCopy = {...userContext};
        userContextCopy.agreedJustNow = false;
        appContext.setUserContext(userContextCopy);
        getAdminResponseForUserAgreement();

      } else {
        //console.log("getting response from chat GPT....");
        getChatGPTResponse();
      }
      
      
      //sendChatMessage(chatSessionId,text,senderId,receiverId);

      
      
    }
    
  },[invokeAPI]);
  
  
  
  const messageList = messages.messageList;

  //console.log(messageList);
  //console.log("MessageComponent...");
  
  const nextIndex = messages.nextIndex + 1;
  
  

  return (
        <div className='scrollable-area'>
          
          <div className="messages">
            
            {
              messageList.map(thisMessage=>(
                <Message key={thisMessage.id} user={user} message={thisMessage}  />
                
              )
                )
            }
            
            
            
      </div>
     
  </div>
  )
}
