import React, { useState } from 'react'
import ReactGA from "react-ga4";
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
import { logUserAction } from '../Data/GoogleAnalyticsLib';
  

export const BottomSheet = ({user,bottomSheetOpen,setBottomSheetOpen,bottomSheetContent}) => {
    
    
    const shareText = 'Hey there - check out this awesome app mydodobot.com. Peace!';
    const phoneNumber = '';
    const signedInUserId = user.attributes.sub;
    const toggleSheet = () => {
    
        setBottomSheetOpen(!bottomSheetOpen);
      }
      const logShareEventToGA = (shareType) => {
        //console.log("share event..."+shareType);
        //console.log(e);
        var shareButtpnType = -1;
        switch(shareType) {
            case 'sms_btn': shareButtpnType=1; break;
            case 'fb_btn': shareButtpnType=2; break;
            case 'fb_msg_btn': shareButtpnType=3; break;
            case 'whatsapp_btn': shareButtpnType=4; break;
            case 'twtr_btn': shareButtpnType=4; break;
            
            case 'email_btn': shareButtpnType=6; break;
            default: shareButtpnType=-1; 
        }
        logUserAction("Social_Sharing_Category","After_Social_Share_Action",signedInUserId,true);
       /* ReactGA.event({
            category: "Social_Sharing",
            action: "After_Social_Share_Action",
            label: signedInUserId, // optional
            value: shareButtpnType, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          */
          

      }
  return (
    <div className={`bottom-sheet ${bottomSheetOpen ? 'open' : ''}`}>
        <div className="bottom-sheet-content">
          <h3>{bottomSheetContent.title}</h3>
          <p>{bottomSheetContent.bodyText}</p>
        
          <div className='social-share-buttons'>
          <div>
                    <img src='text_msg.png' alt='send message' onClick={()=>{
                        window.open(`sms:${phoneNumber}&body=${shareText}`);
                        logShareEventToGA('sms_btn');

                 }}/>
                </div>
          <FacebookShareButton
                url='https://www.mydodobot.com'
                quote={'checkout the Dodo bot - the bumbest chatbot in the world'}
                hashtag="#dodobot"
                onShareWindowClose={()=>{
                    logShareEventToGA('fb_btn');
                }}>
                        <FacebookIcon size={32} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    url='https://www.mydodobot.com'
                    quote={'checkout the Dodo bot - the bumbest chatbot in the world'}
                    hashtag="#dodobot"
                    onShareWindowClose={()=>{
                        logShareEventToGA('fb_msg_btn');
                    }}>
                        <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
                <WhatsappShareButton 
                url='https://www.mydodobot.com'
                onShareWindowClose={()=>{
                    logShareEventToGA('whatsapp_btn');
                }}>
                <WhatsappIcon size={32} round/>

            </WhatsappShareButton>
            <TwitterShareButton
                 url='https://www.mydodobot.com'
                 onShareWindowClose={()=>{
                    logShareEventToGA('twtr_btn');
                }}>
                 <TwitterIcon size={32} round />
                 </TwitterShareButton>
            <EmailShareButton
                subject='checkout the Dodo bot - the bumbest chatbot in the world'
                body='checkout the Dodo bot - the bumbest chatbot in the world'
                onShareWindowClose={()=>{
                    logShareEventToGA('email_btn');
                }}>
                <EmailIcon size={32} round />
            </EmailShareButton>
            <RedditShareButton
                 url='https://www.mydodobot.com'
                 onShareWindowClose={()=>{
                    logShareEventToGA('reddit_btn');
                }}>
                 <RedditIcon size={32} round />
            </RedditShareButton>
            
            
          </div>
          <div><button onClick={toggleSheet}>Close</button></div>
          
        </div>
      </div>
  )
}
