import React, { useState } from 'react'
import { useRef, useEffect } from 'react';
import { MessageTypes } from '../Data/MessageTypes';
import { RecommendationsData } from '../Data/RecommendationsData'
import { UserContext } from '../Context/UserContext';
import { AppContext } from '../Context/AppContext';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { logUserAction } from '../Data/GoogleAnalyticsLib';
import {tts} from '../Data/TextToSpeech'

export const TranslateMessage = ({user,message}) => {
  //console.log("message=%o",message);
  
  
  const [recommendedQuestion1,setRecommendedQuestion1] = useState(RecommendationsData[0]);
  const [recommendedQuestion2,setRecommendedQuestion2] = useState(RecommendationsData[1]);
  const [isAnimated1,setIsAnimated1] = useState(false);
  const [isAnimated2,setIsAnimated2] = useState(false);
  const [isAnimated3,setIsAnimated3] = useState(false);
  const [isAnimated4,setIsAnimated4] = useState(false);
  const [isBadge1Visible,setIsBadge1Visible] = useState(false);
  const [isBadge2Visible,setIsBadge2Visible] = useState(false);
  
  const lastMessageRef = useRef(null);
  const userContext = React.useContext(UserContext);
  //console.log("userContext %o",userContext);
  const appContext = React.useContext(AppContext);
  //console.log("appContext %o",appContext);
  const setDisabled = appContext.setDisabled;
  const setText = appContext.setText;
  const handleMessageSend = appContext.handleMessageSend;
    //console.log("value fom context %o",value);
    //console.log("message.message=%o",message.message);
    
    
    


        
  
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    let recommendationsIndex =  Math.floor(Math.random() * RecommendationsData.length);
    const recommendedQuestion1 = RecommendationsData[recommendationsIndex];
    setRecommendedQuestion1(recommendedQuestion1);
    recommendationsIndex =  Math.floor(Math.random() * RecommendationsData.length);
    const recommendedQuestion2 = RecommendationsData[recommendationsIndex];
    setRecommendedQuestion2(recommendedQuestion2);
  }, [message]);
  

  if (message.senderID === user.attributes.sub) {
    return (
  
      <div key={message.id} ref={lastMessageRef} className="loggedInUser">Me: {message.message}
      
     
      </div>
    )
  } else if (message.messageType === MessageTypes.ADMIN_MESSAGE_TC) {
    return (
    <div  key={message.id} className="message">
        <div className='content'>
       
        {message.message}
      
        <div ref={lastMessageRef} className="suggestions">
          
            <button  onClick={()=>{
                
                setText('Yes I agree');
                
              }}>Yes I agree</button>
              
              
            
        </div>
          
        </div>
      </div>
    )
  } else {
    return (
  
      <div  key={message.id} className="message">
        <div className={`${isBadge1Visible ? 'badge1' : 'invisibleBadge'}`}>💀</div>
        <div className={`${isBadge2Visible ? 'badge2' : 'invisibleBadge'}`}>🤣</div>
        
        <div className='content'>
        
        Translate Bot: {message.message}
        
        
        
        <div className='emojis'>
          
          <CopyToClipboard text={message.message}
              onCopy={() => {
                //console.log('copied');
                alert("✅ message copied to clipboard");
              }}>
          <div className='em3'>
            <span className={`emoji ${isAnimated4 ? 'animated3' : ''}`} onClick={()=>{
                setIsAnimated4(true);
                logUserAction("Emoji_Category","Copy",'copy');
              }}> 📋 </span>
          </div>
              </CopyToClipboard>
        </div>
      
        <div ref={lastMessageRef} className="suggestions">
        <button  onClick={()=>{
              console.log("message = %o",message);
              console.log("message.message = %o",message.message);
                
                tts(message.message);
                
              }}>I want to hear out loud</button>
            <button  onClick={()=>{
                
                setText('Give me the English pronunciation');
                
              }}>Give me the English pronunciation</button>
              
              
            
        </div>
        
          
        </div>
      </div>
    )
  }
  
}