/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUserId
      userAgreeddToTerms
      cognitoUserName
      cognitoUserEmail
      cognitoAuthMethod
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        userAgreeddToTerms
        cognitoUserName
        cognitoUserEmail
        cognitoAuthMethod
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatSession = /* GraphQL */ `
  query GetChatSession($id: ID!) {
    getChatSession(id: $id) {
      id
      userId
      botUserId
      receiverID
      chatMessages {
        items {
          id
          chatSessionID
          senderID
          receiverID
          message
          messageType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatSessions = /* GraphQL */ `
  query ListChatSessions(
    $filter: ModelChatSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        botUserId
        receiverID
        chatMessages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      chatSessionID
      senderID
      receiverID
      message
      messageType
      createdAt
      updatedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatSessionID
        senderID
        receiverID
        message
        messageType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByCognitoUserId = /* GraphQL */ `
  query UsersByCognitoUserId(
    $cognitoUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCognitoUserId(
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        userAgreeddToTerms
        cognitoUserName
        cognitoUserEmail
        cognitoAuthMethod
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatSessionsByUserId = /* GraphQL */ `
  query ChatSessionsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatSessionsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        botUserId
        receiverID
        chatMessages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatSessionsByBotUserId = /* GraphQL */ `
  query ChatSessionsByBotUserId(
    $botUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatSessionsByBotUserId(
      botUserId: $botUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        botUserId
        receiverID
        chatMessages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatMessagesByChatSessionIDAndCreatedAt = /* GraphQL */ `
  query ChatMessagesByChatSessionIDAndCreatedAt(
    $chatSessionID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByChatSessionIDAndCreatedAt(
      chatSessionID: $chatSessionID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatSessionID
        senderID
        receiverID
        message
        messageType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
