import React from 'react'
import { HeaderGuest } from '../Components/HeaderGuest'
import { GuestContent } from '../Components/GuestContent'
import { GuestContentAllServices } from '../Components/GuestContentAllServices'

export const IntroPageAllServices = () => {
  return (
    <div className="mainContainer">
    <HeaderGuest />
    <GuestContentAllServices/>
    </div>
  )
}
