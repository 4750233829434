import React from 'react'
import { Auth } from 'aws-amplify';
import { withAuthenticator  } from '@aws-amplify/ui-react';
 const WithAuthDemo = ({ signOut, user }) => {
    console.log("user within WithAuthDemo %o",user);
  return (
    <div>
        
        WithAuthDemo
        <button onClick={()=>{
            Auth.signOut();
        }}>Sign out</button>
        


    </div>

  )
}
export default withAuthenticator(WithAuthDemo);