/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userAgreeddToTerms
      cognitoUserName
      cognitoUserEmail
      cognitoAuthMethod
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userAgreeddToTerms
      cognitoUserName
      cognitoUserEmail
      cognitoAuthMethod
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userAgreeddToTerms
      cognitoUserName
      cognitoUserEmail
      cognitoAuthMethod
      createdAt
      updatedAt
    }
  }
`;
export const createChatSession = /* GraphQL */ `
  mutation CreateChatSession(
    $input: CreateChatSessionInput!
    $condition: ModelChatSessionConditionInput
  ) {
    createChatSession(input: $input, condition: $condition) {
      id
      userId
      botUserId
      receiverID
      chatMessages {
        items {
          id
          chatSessionID
          senderID
          receiverID
          message
          messageType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatSession = /* GraphQL */ `
  mutation UpdateChatSession(
    $input: UpdateChatSessionInput!
    $condition: ModelChatSessionConditionInput
  ) {
    updateChatSession(input: $input, condition: $condition) {
      id
      userId
      botUserId
      receiverID
      chatMessages {
        items {
          id
          chatSessionID
          senderID
          receiverID
          message
          messageType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatSession = /* GraphQL */ `
  mutation DeleteChatSession(
    $input: DeleteChatSessionInput!
    $condition: ModelChatSessionConditionInput
  ) {
    deleteChatSession(input: $input, condition: $condition) {
      id
      userId
      botUserId
      receiverID
      chatMessages {
        items {
          id
          chatSessionID
          senderID
          receiverID
          message
          messageType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      chatSessionID
      senderID
      receiverID
      message
      messageType
      createdAt
      updatedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      chatSessionID
      senderID
      receiverID
      message
      messageType
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      chatSessionID
      senderID
      receiverID
      message
      messageType
      createdAt
      updatedAt
    }
  }
`;
