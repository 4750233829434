import React from 'react'
import { useNavigate } from "react-router-dom";
import { logUserAction } from '../Data/GoogleAnalyticsLib';

export const GuestContentAllServices = () => {
    const navigate = useNavigate();
  return (
    
    <div className='guest-content'>
        
        <button onClick={() => {
            logUserAction("Try_Now_Category","Try Now", "Try Now on Guest Header");
            navigate("/search?bankname=wellsfargo");

            }}>
               Wellsfargo Employee
         </button>
         <button onClick={() => {
            logUserAction("Try_Now_Category","Try Now", "Try Now on Guest Header");
            navigate("/search?bankname=chime");

            }}>
               Chime Employee
         </button>
        
      
         
         <img className='tempHidden' src='RAGLLMDemo.gif'/>
         <br></br>
         <button className = "tempHidden" onClick={() => {
            logUserAction("Try_Now_Category","Try Now", "Try Now on Guest Header");
            navigate("/search?bankname=chime");

            }}>
               Try Searching Documents
         </button>
        </div>
        
  );
}
