
import ReactGA from "react-ga4";
export const initGA = () => {      
    if (window.location.href.includes('https://www.mydodobot.com')) {
        //console.log("initGA for prod");
        ReactGA.initialize("G-ST3P31PLSR"); //New property created fro prod
    } else {
        //console.log("initGA for non prod");
        ReactGA.initialize("G-LPHZDMWZRM");
    }
    //console.log("GALib - GAInit");
} 

export const logPageView = (path,title) => {
    //console.log("GALib - PageView Logged="+title);
    //console.log("logPageView url"+window.location.href);
    if (window.location.href.includes('https://www.mydodobot.com')) {
        //console.log("logging GA");
        ReactGA.send({ hitType: "pageview", page: path, title: title });
    } else {
        //console.log("skipping GA");
    }
        
}

export const logUserAction = (category,action,label,nonInteraction=false) => {
    //console.log("GALib - User action Logged="+action);
    //console.log("logUserAction url"+window.location.href);
    if (window.location.href.includes('https://www.mydodobot.com')) {
        //console.log("logging GA");
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        nonInteraction: nonInteraction, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    } else {
        //console.log("skipping GA");
    }
}
