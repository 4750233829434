import logo from './logo.svg';
import React, { useState, useEffect } from 'react'
//import './App.css';
import  Home  from './Pages/Home';
import { Login } from './Pages/Login';
import { Signup } from './Pages/Signup';
import '@aws-amplify/ui-react/styles.css';
import './style.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createChatSessionForUser } from './Data/AllDBQueries';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify, Auth, Hub } from 'aws-amplify';


import { withAuthenticator  } from '@aws-amplify/ui-react';

//import awsConfig from './aws-exports';
import awsExports from './aws-exports';
import { IntroPage } from './Pages/IntroPage';
import WithAuthDemo from './Pages/WithAuthDemo';
import TranslateHome from './Pages/TranslateHome';
import SearchHome from './Pages/SearchHome';
import { IntroPageAllServices } from './Pages/IntroPageAllServices';

//console.log("awsExports=");
//console.log(awsExports);
Amplify.configure(awsExports);



/*const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

//console.log("isLocalhost="+isLocalhost);
// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");


const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

//console.log("localRedirectSignIn="+localRedirectSignIn+ " productionRedirectSignIn="+productionRedirectSignIn);
//console.log("awsConfig=");
//console.log(awsConfig);
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}
//console.log("updatedAwsConfig=");
//console.log(updatedAwsConfig);

//Amplify.configure(updatedAwsConfig);
*/
Hub.listen('auth', (data) => {
  //console.log("Hub.auth events data..." + idx);
  //idx++;
  //console.log(data);
  switch (data.payload.event) {
    case 'signIn':
      
        break;
    case 'signUp':
        //console.log('Hub : new user signed up');
        //console.log(data);
        //create a chat session
        const newlyCreatedUserId = data.payload.data.userSub;
        //console.log('Step 1: calling the session API to create new session for newly signed up user ..' + newlyCreatedUserId);
        //const setChatSessionId = () => {};
        //createChatSessionForUser(newlyCreatedUserId,setChatSessionId);
        //console.log("Step 4: chatSession Created....printing chatSession Data");
        //console.log(chatSession);
         // create a new message from DODO bot
         //console.log("Step 4: adding first message from dodo bot to the session .....");
        break;
    case 'signOut':
        break;
    case 'signIn_failure':
        break;
    case 'configured':
        break;
    default:
      
  }
});


function App() {
  const { user } = useAuthenticator();
  //console.log("AApp user = %o",user);
  if (user) {

  return (
    
    <BrowserRouter> 
      <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/chat" element={<Home/>}/>
            <Route path="/home" element={<IntroPageAllServices/>}/>
            <Route path="/translate" element={<TranslateHome/>}/>
            <Route path="/search" element={<SearchHome/>}/>
        
      </Routes>
    
    </BrowserRouter>
    
  );
  } else {
    return (
    
      <BrowserRouter> 
        <Routes>
              <Route path="/" element={<IntroPageAllServices/>}/>
              <Route path="/home" element={<IntroPage/>}/>
              <Route path="/chat" element={<Home/>}/>
              <Route path="/translate" element={<TranslateHome/>}/>
              <Route path="/search" element={<SearchHome/>}/>
          
        </Routes>
      
      </BrowserRouter>
      
    );
  }
  
  
}

export default (App);
