export const RecommendationsData = [
        "why is the sky blue",
        "what's the capital of usa",
        "how many days in a leap year",
        "what is 1+1",
        "why does boat float on water",
        "who is obama",
        "why do we sneeze",
        "why dogs sniff",
        "what is sun",
        "what 100+100",
        "what's the capital of paris",
        "what does the fox say",
        "what is 2 multiplied by 4",
        "what is gravity",
        "what causes rain",
        "how are rainbows formed",
        "what's the value of pi",
        "what's fastest land animal",
        "how many continents in the world",
        "where is great wall of china",
        "what is a proton"

       
];