import React, { useState } from 'react'
import ReactGA from "react-ga4";
import { Auth } from 'aws-amplify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { logUserAction } from '../Data/GoogleAnalyticsLib';
import {UserContext} from '../Context/UserContext';
import { ChatSessionContext } from '../Context/ChatSessionContext';
import { useNavigate } from "react-router-dom";
export const HeaderSearch = ({user, bottomSheetOpen,setBottomSheetOpen,bottomSheetContent,setBottomSheetContent}) => {
  const navigate = useNavigate();
  //console.log("chatSesionCtx %o",chatSesionCtx);
  
  return (
    
    <div className="header">
       <div className='logo'>
            <img src="search.png"/>
            <p>DocuSearch</p>

        </div>
        <div className='username'>{user && user.getUsername()}</div>
        
        
        <div className='header-toolbar'> 
        <img src='home.png' alt='logout' onClick={() => {
              logUserAction("Back_To_Home_Category","Home", user.getUsername())
             
                navigate("/home");
              }}/>
        <CopyToClipboard text='Checkout http://www.docusearch.tec'
          onCopy={() => {
            //console.log('copied');
          }}>
             <img className='tempHidden' src='share-solid.png' alt='share with friends' onClick={()=>{
              logUserAction("Social_Sharing_Category","Share_Action_On_Header",user.getUsername());
              /*ReactGA.event({
                category: "Social_Sharing",
                action: "Share_Action_On_Header",
                label: user && user.getUsername(), // optional
                nonInteraction: false, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });*/
               const bottomSheetContentCopy = {...bottomSheetContent};
               bottomSheetContentCopy.title = 'Share Dodo bot with friends';
               bottomSheetContentCopy.bodyText = "Share mydodobot.com with your friends. We have copied the link so simply paste it to send to friends";
               setBottomSheetContent(bottomSheetContentCopy);
               setBottomSheetOpen(true);
          //alert("Share mydodobot.com with your friends. We have copied the link so simply paste it to send to friends");
        }} />
         
        </CopyToClipboard>
       
        <img className='tempHidden' src='user-solid.png' alt='my username' onClick={()=>{
          //alert("You are logged in as " + user.getUsername());
          
          const bottomSheetContentCopy = {...bottomSheetContent};
          bottomSheetContentCopy.title = 'Logged In As';
          bottomSheetContentCopy.bodyText = user.getUsername();
          setBottomSheetContent(bottomSheetContentCopy);
          setBottomSheetOpen(true);


        }}/>
            
            
            
            <img src='user-slash.png' alt='logout' onClick={() => {
              logUserAction("SignIn_SignOut_Category","SignOut", user.getUsername())
              /*ReactGA.event({
                category: "SignIn_SignOut-Category,
                action: "SignOut",
                label: user.getUsername(), // optional
                nonInteraction: false, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });*/
                Auth.signOut();
                navigate("/");
              }}/>

<img className='tempHidden' src='help-web-button.png' alt='help' onClick={() => {
              logUserAction("Help_Category","Help", user.getUsername())
              const bottomSheetContentCopy = {...bottomSheetContent};
              bottomSheetContentCopy.title = 'I am here to help';
              bottomSheetContentCopy.bodyText = 'To get help, send email to spicewoodlabs@gmail.com';
              setBottomSheetContent(bottomSheetContentCopy);
              setBottomSheetOpen(true);
            }}/>
            </div>
        
            
    </div>
    
  )
}