import React from 'react'
import {UserContext} from '../Context/UserContext';



export const FooterSearch = ({text,setText,handleMessageSend,disabled,loader}) => {
  
  const userContext = React.useContext(UserContext); 
  //console.log("value fom context %o",userContext);
  const userAgreeddToTerms = userContext && userContext.userAgreeddToTerms;
  //console.log("userAgreeddToTerms %o",userAgreeddToTerms);
  /*if (!userAgreeddToTerms) {
    console.log("userAgreeddToTerms %o",userAgreeddToTerms);
    disabled=true;
  } else {
    disabled=false;
  }
  */
  
  return (
    <div className="footer">
         <div className="inputMessage">
        
        <div className="formContainer">
        
              <input type="text" onChange={e=>setText(e.target.value)} value={text} placeholder='Search internal documents' disabled={disabled}/>
              <button onClick={handleMessageSend} disabled={disabled}>Search <span className={loader ? 'loader-visible' : 'loader-hidden'}></span></button>

        </div>
      </div>
        
        
    </div>
  )
}
