import React from 'react'
import { useNavigate } from "react-router-dom";
import { logUserAction } from '../Data/GoogleAnalyticsLib';

export const GuestContent = () => {
    const navigate = useNavigate();
  return (
    
    <div className='guest-content'>
        <h2>Welcome Dodo Bot Guest</h2>
        <p>I am Dodo - the dumbest chat bot ever made. You can try asking me questions such as "why is the sky blue" or "whats 1+1" and I will give you the most dumb answers.</p>
        <button onClick={() => {
            logUserAction("Try_Now_Category","Try Now", "Try Now on Guest content");
            navigate("/chat");

            }}>
                Try Now
         </button>
         <h2>Examples</h2>
         <div className='screenshot'>
         <div className='screenshot-1'>
             <img src='screenshot1.png'/>
             </div>
            <div className='screenshot-1'>
             <img src='screenshot2.png'/>
             </div>
            
             
         
            
            
         </div>
        </div>
        
  );
}
