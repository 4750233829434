import React, { useState } from 'react'
import { logUserAction } from '../Data/GoogleAnalyticsLib';


import { useNavigate } from "react-router-dom";
export const HeaderGuest = () => {
  
  //console.log("chatSesionCtx %o",chatSesionCtx);
  const navigate = useNavigate();
  
  return (
    
    <div className="header">
      <div className='logo'>
            <img src="search.png"/>
            <p>DocuSearch</p>

        </div>
        
        <div className='username'>Welcome Guest</div>
        
        
        <div className='header-toolbar'> 
        <button className="tempHidden" onClick={() => {
            logUserAction("Try_Now_Category","Try Now", "Try Now on Guest Header");
            navigate("/search");

            }}>
               Try Searching Documents
         </button>
         
        </div>
        
            
    </div>
    
  )
}