import React, { useState } from 'react'
import { Input }from '../Components/Input'
import { Message }from '../Components/Message';
import { useEffect, useRef } from 'react';
import { UserContext } from '../Context/UserContext';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify'
import {  sendChatMessage , saveChatGPTResponseToDB } from '../Data/AllDBQueries'
import { AppContext } from '../Context/AppContext';
import { TranslateMessage } from './TranslateMessage';


export const TranslateMessages = ({user,chatSessionId,text,setText,loader,setLoader,invokeAPI,setInvokeAPI,messages,setMessages,recommendationPanelVisible,setRecommendationPanelVisible,recommendedQuestion,setRecommendedQuestion,setDisabled}) => {
  
  const inputElement = useRef(null);
  const thisUserId = '10001';
  //console.log("Messages component - chatSessionId= "+chatSessionId);
  
  const [chatGPTMessageList,setcChatGPTMessageList] = useState([]);
  const userContext = React.useContext(UserContext); 
  const appContext = React.useContext(AppContext); 
  //console.log("value fom context %o",value);
  useEffect(() => {
    
    /*inputElement.current.onfocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };
    */
    //console.log("Message Component useEffect called");
    //console.log("chatGPTMessageList...");
    //console.log(chatGPTMessageList);
    
    
    const stupidText = text + ". If possible, please also include the pronunciation of the translated text. Output should be structured in JSON \n\n Output JSON format: \n\n { \"source_text\": \"have a nice day\", \"source_language\": \"en\", \"target_text\": \"良い一日を\", \"target_language\": \"ja\",  \"pronunciation\": \"yo-i i-chi-ni-chi o\" }";

    
    
    
    if (invokeAPI) {
      
      setLoader(true);
      const chatGPTUserObj = {
        role:'user',
        content:stupidText
      };
      const chatGPTMessageListCopy = [...chatGPTMessageList];
      chatGPTMessageListCopy.push(chatGPTUserObj);
      const getAdminResponseForUserAgreement = async () => {
        const content = "Thank you for agreeing to my terms and conditions. You are sweet! Now you can enjoy the app"
        const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
        const receiverID =   user.attributes.sub;
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
        saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
      }
      const getChatGPTResponse = async () => {
        const { Configuration, OpenAIApi } = require("openai");
        const configuration = new Configuration({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });
        //console.log("key...");
        //console.log(process.env.REACT_APP_OPENAI_API_KEY);
        const openai = new OpenAIApi(configuration);
        //console.log("user text = " + text);
        //console.log('chatGPTMessageListCopy %o', chatGPTMessageListCopy);
        const completion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: chatGPTMessageListCopy ,
        });
        //console.log("response from openAI...");
        //console.log(completion.data.choices[0].message);
    
        chatGPTMessageListCopy.push(completion.data.choices[0].message);
        

        setcChatGPTMessageList(chatGPTMessageListCopy);
        const role = completion.data.choices[0].message.role;
        const contentFromChatGPT = completion.data.choices[0].message.content;
        console.log("contentFromChatGPT = %o",contentFromChatGPT);
        let contentSavedInDB = contentFromChatGPT;
        try {
          const jsonResponse = JSON.parse(contentFromChatGPT);
          console.log("json = %o",jsonResponse);
          const originalText = jsonResponse.source_text;
          const translatedText = jsonResponse.target_text;
          const originalLanguage = jsonResponse.source_language;
          const targetLanguage = jsonResponse.target_language;
          const englishPronunciation = jsonResponse.pronunciation;
          contentSavedInDB = "Translation of '" + originalText + "' from '" + originalLanguage + "' to '" + targetLanguage + "' is '" + translatedText + "'. The English pronunciation is: '" + englishPronunciation + "'";
          
          console.log("content = %o",contentSavedInDB);
        } catch (jsonParsingError) {
          console.log("Error %o",jsonParsingError);
        }
          
          const senderID ='e84fc505-7627-4e48-b460-dbee87482d2b';
          const receiverID =   user.attributes.sub;
        //console.log("saving message in DB...");
        //saveChatGPTResponseToDB(chatSessionId,messages,setMessages,content,senderID,receiverID,setInvokeAPI);
          saveChatGPTResponseToDB(chatSessionId,messages,setMessages,contentSavedInDB,senderID,receiverID,setInvokeAPI,setText,setDisabled,setLoader,setRecommendationPanelVisible,setRecommendedQuestion) ;
        //console.log("//saving message in DB...");
        //console.log(content);
        /** move this to all db queries */
        
        

        
        
      
      };
      //console.log("making a request...");
      //console.log("text while making request = " + text)
      //console.log("userContext = %o",userContext);
      const agreedJustNow = userContext.agreedJustNow;
      //console.log('agreedJustNow %s',agreedJustNow)
      if (agreedJustNow && text === 'Yes I agree')  {
        //console.log("getting response from admin....");
        const userContextCopy = {...userContext};
        userContextCopy.agreedJustNow = false;
        appContext.setUserContext(userContextCopy);
        getAdminResponseForUserAgreement();

      } else {
        //console.log("getting response from chat GPT....");
        getChatGPTResponse();
      }
      
      
      //sendChatMessage(chatSessionId,text,senderId,receiverId);

      
      
    }
    
  },[invokeAPI]);
  
  
  
  const messageList = messages.messageList;

  //console.log("messageList in TranslateMessages ==== %o",messageList);
  //console.log("MessageComponent...");
  
  const nextIndex = messages.nextIndex + 1;
  
  

  return (
        <div className='scrollable-area'>
          
          <div className="messages">
            
            {
              messageList.map(thisMessage=>(
                <TranslateMessage key={thisMessage.id} user={user} message={thisMessage}  />
                
              )
                )
            }
            
            
            
      </div>
     
  </div>
  )
}
